import { Button, KeyboardButton } from "@common/index";
import styles from "./styles.module.scss";
import { SOMATIC_KEYS, useName } from "src/routes/onboarding/shared";
import { useNavigate } from "react-router";

export const Name = () => {
  const navigate = useNavigate();
  const onNextStep = () => {
    navigate("/prediction");
  };
  const { name, isValid, handleChange, onSubmit } = useName(onNextStep);

  const handleSubmit = async () => {
    localStorage.setItem(SOMATIC_KEYS.name, JSON.stringify(name));
    onSubmit();
  };

  return (
    <div className={styles.container} id="breakdown">
      <div className={styles.content}>
        <h2>What's your name?</h2>
        <div className={styles.input}>
          <input
            placeholder="Name"
            type="text"
            value={name}
            onChange={(e) => handleChange(e.currentTarget.value)}
          />
        </div>
      </div>
      <div className={styles.action}>
        <Button onClick={handleSubmit} disabled={!isValid}>
          Continue
        </Button>
      </div>
      <KeyboardButton onClick={handleSubmit} disabled={!isValid}>
        Continue
      </KeyboardButton>
    </div>
  );
};
