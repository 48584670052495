import { useEffect, useState } from "react";
import { useDiscount } from "../routes/onboarding/shared/components/paywall/DiscountContext";

const useDiscountCountdown = (customInterval) => {
  const { setIsDiscounted } = useDiscount();
  const [isTimeUp, setIsTimeUp] = useState(false);

  const interval = customInterval || 60 * 10 * 1000;

  const [remainingTime, setRemainingTime] = useState(() => {
    const savedEndTime = localStorage.getItem("endTime");
    return savedEndTime ? Math.max(savedEndTime - Date.now(), 0) : interval;
  });

  useEffect(() => {
    const storageKey = customInterval ? "upsaleEndTime" : "endTime";

    if (!localStorage.getItem(storageKey)) {
      localStorage.setItem(storageKey, Date.now() + interval);
    }

    const updateRemainingTime = () => {
      const endTime = localStorage.getItem(storageKey);
      const timeLeft = endTime ? Math.max(endTime - Date.now(), 0) : 0;

      if (timeLeft > 0) {
        setRemainingTime(timeLeft);
      } else {
        setIsDiscounted(false);
        setIsTimeUp(true);
        clearInterval(timerInterval);
      }
    };

    const timerInterval = setInterval(updateRemainingTime, 1000);

    return () => clearInterval(timerInterval);
  }, [setIsDiscounted, interval, customInterval]);

  const minutes = Math.floor(remainingTime / 60000);
  const seconds = Math.floor((remainingTime % 60000) / 1000);
  const displayMinutes = String(minutes).padStart(2, "0");
  const displaySeconds = String(seconds).padStart(2, "0");

  const time = { minutes, seconds, displayMinutes, displaySeconds };

  return { time, isTimeUp };
};

export default useDiscountCountdown;
