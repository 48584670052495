import { Fire, FitnessSlimWaist, FitnessWeights, Running } from "@assets/Icons";
import { Button, Loader } from "@common/index";
import { BMIHint } from "./BMIHint";
import styles from "../../../../shared/components/report/styles.module.scss";
import Normal from "./assets/img/normal.png";
import Overweight from "./assets/img/overweight.png";
import Underweight from "./assets/img/underweight.png";
import Obese from "./assets/img/obese.png";
import { useRef, useEffect, useState } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import "../../../../shared/components/report/fade.css";

import { useGetFitnessProfile } from "@hooks/useGetFitnessProfile";
import BMIScale from "src/routes/onboarding/shared/components/report/BMIScale";

const BMI_TYPE_IMAGES = {
  Normal,
  Overweight,
  Obese,
  Underweight,
};

export const Report = ({ onNextStep }) => {
  const { profile, loaded } = useGetFitnessProfile();
  const [showContent, setShowContent] = useState(false);
  const nodeRef = useRef();

  const bmiType = profile?.bodyMassType;

  useEffect(() => {
    if (loaded) {
      setTimeout(() => {
        setShowContent(true);
      }, 1000);
    }
  }, [loaded]);

  if (!showContent) {
    return (
      <div className={styles.container}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        <h2>Your current fitness level overview</h2>
        <div className={styles.content}>
          <h3>Body Mass Index (BMI)</h3>
          <BMIScale bmi={profile?.bodyMassIndex} />
          <div
            className={
              !!profile ? `${styles.hint}` : `${styles.hidden} ${styles.hint}`
            }
          >
            <BMIHint bmiType={bmiType} />
          </div>
          <SwitchTransition mode="out-in">
            <CSSTransition
              in={!!profile}
              nodeRef={nodeRef}
              timeout={700}
              classNames="fade-in"
              key={profile}
            >
              <div
                ref={nodeRef}
                className={
                  !!profile
                    ? `${styles.summary}`
                    : `${styles.summary} ${styles.hidden}`
                }
              >
                <div className={styles.summaryItems}>
                  <div className={styles.summaryItem}>
                    <div className={styles.icon}>
                      <FitnessSlimWaist />
                    </div>
                    <div className={styles.text}>
                      <p>Body Type</p>
                      <h5>{profile?.bodyType}</h5>
                    </div>
                  </div>
                  <div className={styles.summaryItem}>
                    <div className={styles.icon}>
                      <Running />
                    </div>
                    <div className={styles.text}>
                      <p>Lifestyle</p>
                      <h5>{profile?.lifestyleType || "n/a"}</h5>
                    </div>
                  </div>
                  <div className={styles.summaryItem}>
                    <div className={styles.icon}>
                      <FitnessWeights />
                    </div>
                    <div className={styles.text}>
                      <p>Fitness level</p>
                      <h5>{profile?.fitnessLevel}</h5>
                    </div>
                  </div>
                  <div className={styles.summaryItem}>
                    <div className={styles.icon}>
                      <Fire />
                    </div>
                    <div className={styles.text}>
                      <p>Metabolism</p>
                      <h5>{profile?.metabolismType}</h5>
                    </div>
                  </div>
                </div>
                <div className={styles.image}>
                  <img
                    src={
                      BMI_TYPE_IMAGES[bmiType] || BMI_TYPE_IMAGES.Underweight
                    }
                    alt={bmiType}
                  />
                </div>
              </div>
            </CSSTransition>
          </SwitchTransition>
        </div>
      </div>
      <div className={styles.action}>
        <Button onClick={() => onNextStep()}>Continue</Button>
      </div>
    </div>
  );
};
