import styles from "./styles.module.scss";
import { Button } from "@common/index";
import useDiscountCountdown from "@hooks/useDiscountCountdown";
import { useEffect } from "react";

export const Timer = ({ onGetMyPlanClick, onTimeEnd }) => {
  const {
    time: { displayMinutes, displaySeconds },
    isTimeUp
  } = useDiscountCountdown();

  useEffect(() => {
    if (isTimeUp) {
      onTimeEnd();
    }
  }, [isTimeUp, onTimeEnd]);

  return (
    <div className={styles.timerContainer}>
      <div className={styles.timer}>
        <div className={styles.time}>
          <p>{displayMinutes}</p>
          <span>minutes</span>
        </div>
        <p>:</p>
        <div className={styles.time}>
          <p>{displaySeconds}</p>
          <span>seconds</span>
        </div>
      </div>
      <div className={styles.button}>
        <Button onClick={onGetMyPlanClick}>GET MY PLAN</Button>
      </div>
    </div>
  );
};
