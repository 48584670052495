import styles from "./styles.module.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { Back, Login } from "../../assets/Icons";
import { Button } from "../button";

export const OnboardingLayout = ({
  children,
  headerContent,
  onNavigateBack,
  hideNavigateBack = false,
  hideNavigation = false,
  underHeaderContent = false,
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const determineMeasurementSystem = () => {
      try {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const usesImperial = timeZone.includes("America");

        const measurementSystem = usesImperial ? "us" : "eu";

        const storedMs = localStorage.getItem("ms");
        if (!storedMs) {
          localStorage.setItem("ms", measurementSystem);
        }
      } catch (error) {
        console.error("Failed to determine measurement system:", error);

        const storedMs = localStorage.getItem("ms");
        if (!storedMs) {
          localStorage.setItem("ms", "us");
        }
      }
    };

    determineMeasurementSystem();
  }, []);
  const navigate = useNavigate();
  const toggleBurger = () => {
    setOpen(!open);
  };
  const handleNavigateBack = () => {
    if (onNavigateBack) {
      onNavigateBack();
    } else {
      navigate(-1);
    }
  };

  return (
    <div className={styles.layout}>
      <div className={styles.headerWrapper}>
        <header
          className={`${styles.header} ${
            underHeaderContent ? styles.withUnderHeader : ""
          }`}
        >
          <div className={styles.headerItem}>
            {!hideNavigateBack && (
              <button className={styles.backBtn} onClick={handleNavigateBack}>
                <Back />
              </button>
            )}
          </div>
          {headerContent || <p className={styles.logo}>EasyFit</p>}
          {hideNavigation ? (
            <div className={styles.headerItem}></div>
          ) : (
            <div
              className={
                open ? `${styles.burger} ${styles.open}` : `${styles.burger}`
              }
              onClick={toggleBurger}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          )}
          <nav
            className={
              open
                ? `${styles.navigation}  ${styles.open}`
                : `${styles.navigation} ${styles.close}`
            }
          >
            <ul>
              <li>
                <Link to="/terms-of-use">Terms of Use</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/subscription-policy">Subscription Policy</Link>
              </li>
            </ul>
            <div className={styles.loginNav}>
              <Button onClick={() => navigate("/auth")}>
                Login <Login />
              </Button>
            </div>
            <div className={styles.contact}>
              Contact us:{" "}
              <a href="mailto:support@easyfit.me">support@easyfit.me</a>
            </div>
          </nav>
        </header>
        {underHeaderContent}
      </div>
      <main className={styles.main}>{children}</main>
    </div>
  );
};
