import { useState, useEffect } from "react";
import { Button, Input } from "@common/index";
import { useMeasureSystem } from "@hooks/useMeasureSystem";
import { cmToFt, ftToFtIn } from "@utils/measurements";
import styles from "./styles.module.scss";
import { Checkbox, CheckboxChecked } from "@assets/Checkbox";
import { Link } from "react-router-dom";
import { UserService } from "@api/services/userService";
import { Tooltip } from "src/routes/onboarding/shared/components/tooltip";
import fingerup from "@assets/img/emoji/fingerup.png";

const title = "Calculating your body mass index";
const tooltipContent =
  "BMI is widely used as a risk factor for the development of or the prevalence of several health issues.";

export const Height = ({ onNextStep }) => {
  const { ms, changeMs } = useMeasureSystem();
  const [heightEu, setHeightEu] = useState();
  const [heightUS, setHeightUS] = useState({ ft: undefined, inch: undefined });
  const [isValid, setIsValid] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    setIsValid(false);
    setHeightEu(undefined);
    setHeightUS({ ft: undefined, inch: undefined });
  }, [ms]);

  const handleChangeCm = (e) => {
    const cm = e.currentTarget.value;

    if (cm === "") {
      setHeightEu(undefined);
      setIsValid(false);
      return;
    }

    if (cm.length <= 3 && !isNaN(cm)) {
      const numericValue = Number(cm);
      setHeightEu(numericValue);
      setIsValid(numericValue <= 241 && numericValue >= 90);
    }
  };

  const handleChangeFt = (e) => {
    const ft = e.currentTarget.value;

    if (ft === "") {
      setHeightUS((prevState) => ({
        ...prevState,
        ft: undefined,
      }));
      setIsValid(false);
      return;
    }

    if (!isNaN(ft)) {
      const numericValue = Number(ft);
      setHeightUS((prevState) => ({
        ...prevState,
        ft: numericValue,
      }));
      setIsValid(
        numericValue >= 3 &&
          numericValue <= 7 &&
          heightUS.inch !== undefined &&
          heightUS.inch >= 0 &&
          heightUS.inch < 12
      );
    }
  };

  const handleChangeInch = (e) => {
    const inch = e.currentTarget.value;

    if (inch === "") {
      setHeightUS((prevState) => ({
        ...prevState,
        inch: undefined,
      }));
      setIsValid(false);
      return;
    }

    if (!isNaN(inch)) {
      const numericValue = Number(inch);

      if (numericValue <= 11) {
        setHeightUS((prevState) => ({
          ...prevState,
          inch: numericValue,
        }));
        setIsValid(
          heightUS.ft !== undefined &&
            heightUS.ft >= 3 &&
            heightUS.ft <= 7 &&
            numericValue >= 0 &&
            numericValue < 12
        );
      } else {
        setIsValid(false);
      }
    }
  };

  const handleNextStep = async () => {
    const payload =
      ms === "us"
        ? { qqHeightFt: heightUS.ft, qqHeightIn: heightUS.inch || 0 }
        : { qqHeightCm: heightEu };
    try {
      await UserService.updateQuestions(payload);
      onNextStep();
    } catch (e) {
      console.log(e);
    }
  };

  const handleFocus = () => {
    setShowTooltip(true);
  };

  const handleBlur = () => {
    setShowTooltip(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2>How tall are you?</h2>
        <div className={styles.switch}>
          <div
            onClick={() => changeMs("us")}
            className={
              ms === "us" ? `${styles.item} ${styles.active}` : `${styles.item}`
            }
          >
            ft
          </div>
          <div
            onClick={() => changeMs("eu")}
            className={
              ms === "eu" ? `${styles.item} ${styles.active}` : `${styles.item}`
            }
          >
            cm
          </div>
        </div>
        <div className={styles.input}>
          {ms === "eu" ? (
            <Input
              inputmode="numeric"
              type="number"
              step="1"
              placeholder={"Height"}
              value={heightEu}
              onChange={handleChangeCm}
              endAdornment={<h5>cm</h5>}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
          ) : (
            <div className={styles.usInputs}>
              <Input
                inputmode="numeric"
                onChange={handleChangeFt}
                type="number"
                step="1"
                value={heightUS.ft}
                placeholder={"Height"}
                endAdornment={<h5>ft</h5>}
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
              <Input
                inputmode="numeric"
                value={heightUS.inch}
                maxLength={3}
                onChange={handleChangeInch}
                type="number"
                step="1"
                placeholder={"Height"}
                endAdornment={<h5>in</h5>}
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
            </div>
          )}
          <p className={styles.hint}>
            Please, enter a value from{" "}
            <span>{ms === "eu" ? `90 cm to 241 cm` : `3ft to 7ft 11 in`}</span>
          </p>
        </div>
        {showTooltip && (
          <Tooltip
            icon={fingerup}
            title={title}
            content={tooltipContent}
            background="info"
          />
        )}
      </div>
      <div className={styles.action}>
      
        <Button onClick={handleNextStep} disabled={!isValid}>
          Next Step
        </Button>
      </div>
    </div>
  );
};
