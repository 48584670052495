import { useStripe } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";

export const usePaymentButtonMethod = () => {
  const [paymentData, setPaymentData] = useState({
    paymentMethod: null,
    idPrefix: "",
  });

  const [isLoading, setIsLoading] = useState(true);

  const [isExpressCheckoutAvailable, setExpressCheckoutAvailable] =
    useState(false);

  const stripe = useStripe();

  console.log("isExpressCheckoutAvailable", isExpressCheckoutAvailable);
  console.log("paymentData", paymentData);

  useEffect(() => {
    if (!stripe) return;

    const checkExpressCheckout = async () => {
      try {
        setIsLoading(true);
        const paymentRequest = stripe.paymentRequest({
          country: "US",
          currency: "usd",
          total: {
            label: "Test Payment",
            amount: 1000,
          },
          requestPayerName: true,
          requestPayerEmail: true,
        });

        const result = await paymentRequest.canMakePayment();


        if (result?.applePay) {
          setPaymentData({
            paymentMethod: "APPLE_PAY",
            idPrefix: "apple-pay_",
          });
          setExpressCheckoutAvailable(true);
        } else if (result?.googlePay) {
          setPaymentData({
            paymentMethod: "GOOGLE_PAY",
            idPrefix: "google-pay_",
          });
          setExpressCheckoutAvailable(true);
        } else {
          setExpressCheckoutAvailable(false);
        }
      } catch (error) {
        console.error("Error checking express checkout availability:", error);
        setExpressCheckoutAvailable(false);
      } finally {
        setIsLoading(false);
      }
    };

    checkExpressCheckout();
  }, [stripe]);

  return {
    ...paymentData,
    isExpressCheckoutAvailable,
    isLoading,
  };
};
