import styles from "./styles.module.scss";

import unsure from "@assets/img/emoji/unsure.png";
import muscle from "@assets/img/emoji/muscle.png";
import thinking from "@assets/img/emoji/thinking.png";

import { useNavigate } from "react-router";
import { IconCard, SOMATIC_KEYS } from "../../../../shared";
import { useGetUserInfo } from "@hooks/useGetUserProfile";
import { Loader } from "@common/loader";

const data = [
  {
    value: "I believe I can do it!",
    displayValue: "I believe I can do it!",
    emoji: muscle,
  },
  {
    value: "I'm uncertain, but willing to try!",
    displayValue: "I'm uncertain, but willing to try!",
    emoji: thinking,
  },
  {
    value: "I'm still really unsure",
    displayValue: "I'm still really unsure",
    emoji: unsure,
  },
];
export const Confident = () => {
  const ms = localStorage.getItem("ms");
  const navigate = useNavigate();
  const { user, loaded } = useGetUserInfo();

  const goal =
    ms === "us"
      ? user?.questions?.qqGoalWeightLbs
      : user?.questions?.qqGoalWeightKg;

  console.log(user);
  const handleNextStep = (value) => {
    console.log(value);
    localStorage.setItem(SOMATIC_KEYS.confident, JSON.stringify(value));
    navigate("/plan-chart");
  };

  return (
    <div className={styles.container} id="breakdown">
      {loaded ? (
        <div className={styles.content}>
          <h2>
            How confident are you in reaching{" "}
            <span>
              {goal} {ms === "us" ? "lbs" : "kg"}
            </span>
          </h2>
          <div className={styles.cards}>
            {data.map((value) => (
              <IconCard
                onClick={() => handleNextStep(value.value)}
                img={value.emoji}
                title={value.displayValue}
                key={value.value}
              />
            ))}
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};
