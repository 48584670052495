import { BreakdownWithImage, SOMATIC_KEYS } from "../../../../shared";
import img from "./img.png";

export const WithoutStress = ({ onNextStep }) => {
  const readyToLoseAnswer = localStorage.getItem(SOMATIC_KEYS.weightChange);
  const isStruggleToGain = readyToLoseAnswer === "STRUGGLE_WITH_WEIGHT_GAIN";

  const title = isStruggleToGain
    ? "Get toned and fit without added stress"
    : "Lose excess weight without added stress";

  const paragraphText = isStruggleToGain
    ? "stress-free lifestyle"
    : "weight loss";

  const handleContinue = () => {
    onNextStep?.();
  };

  const paragraph = [
    "Dealing with constant stress can lead to sugar cravings and stress eating, but you can combat it with somatic exercises. ",
    `<strong>Personal workout plan</strong> are your secret to healthy ${paragraphText}. They help relieve stress, keep you motivated, and boost mindfulness.`,
  ];
  return (
    <BreakdownWithImage
      img={img}
      title={title}
      paragraph={paragraph}
      onContinue={handleContinue}
    />
  );
};
