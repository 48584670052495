import styles from "./styles.module.scss";
import appStore from "./assets/appstore.png";
import loginScreen from "./assets/loginscreen.png";
import welcomeScreen from "./assets/welcomescreen.png";
import { Button } from "@common/button";
import { OnboardingLayout } from "@common/onboardingLayout";
import { useEffect, useState } from "react";
import email from "../../assets/img/png/email.png";
import { useSelector } from "react-redux";
import { axiosInstance } from "../../api";
import { errorToast, successToast } from "../../utils/toaster";

const EmailVersion = () => {
  const { userInfo } = useSelector((state) => state.user);

  const handleResend = async () => {
    try {
      await axiosInstance.post("/me/send-portal-access-email");
      successToast({ message: "Email has been sent" });
    } catch (e) {
      errorToast({ message: "Unexpected error occured" });
      console.log(e);
    }
  };

  return (
    <div className={styles.emailVersion}>
      <img src={email} alt="email" />
      <h3>Check your email</h3>
      <h5>
        We've sent sent login instructions to your email address:
        <br />
        <span>{userInfo?.email}</span>
      </h5>
      <p className={styles.resend}>
        Didn't receive an email? <a onClick={handleResend}>Resend</a>
      </p>
    </div>
  );
};

const AppStoreVersion = () => {
  return (
    <OnboardingLayout hideNavigateBack>
      <div className={styles.appleVersion}>
        <h2>Thank you!</h2>
        <p className={styles.subtitle}>You can download the app</p>

        <div className={styles.step}>
          <div className={styles.stepNumber}>1</div>
          <p>
            <span>Download</span> the app using the link below
          </p>
          <a
            href="https://apps.apple.com/us/app/easyfitme-workout-tracker-app/id6737345325"
            className={styles.appStoreButton}
          >
            <img src={appStore} alt="Download on the App Store" />
          </a>
        </div>

        <div className={styles.step}>
          <div className={styles.stepNumber}>2</div>
          <p>
            Tap "<span>Already have an account? </span>
            <span className={styles.link}> Log in</span>"
          </p>
          <img
            src={welcomeScreen}
            alt="Login screen"
            className={styles.screenImage}
          />
        </div>

        <div className={styles.step}>
          <div className={styles.stepNumber}>3</div>
          <p>
            Enter your <span>email</span> & <span>password</span>
          </p>
          <img
            src={loginScreen}
            alt="Auth screen"
            className={styles.screenImage}
          />
        </div>

        <div className={styles.action}>
          <Button>DOWNLOAD APP</Button>
        </div>
      </div>
    </OnboardingLayout>
  );
};

export const ThankyouPage = () => {
  const [isAppleDevice, setIsAppleDevice] = useState(false);

  useEffect(() => {
    const checkPlatform = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      const isApple = /iphone|ipad|ipod|macintosh|mac os/.test(userAgent);

      setIsAppleDevice(isApple);
    };

    checkPlatform();
  }, []);

  return isAppleDevice ? <AppStoreVersion /> : <EmailVersion />;
};
