import { useState } from "react";
import styles from "./styles.module.scss";
import { getPlanDuration, getPlanPricing } from "../../utils/getPlanDetails";
import { Card } from "../card";
import { PaymentButton } from "../../paymentButton";
import PaddleCheckout from "../../paypal";
import { usePaymentButtonMethod } from "../../hooks/usePaymentMethod";

export const CustomCheckoutModal = ({ onClose, activePlan }) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState("credit-card");

  const { priceNew, priceOld, perDay, currency, duration, discountPercentage } =
    getPlanPricing(activePlan);

  const { idPrefix } = usePaymentButtonMethod();
  const durationText = getPlanDuration(duration);

  const handlePaymentMethodChange = (method) => {
    setSelectedPaymentMethod(method);
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <button className={styles.closeButton} onClick={onClose}>
          &times;
        </button>

        <h2>Checkout</h2>
        
        <div className={styles.section}>
          <div className={styles.planDetails}>
            <p>{durationText}</p>
            <p>
              {priceOld} {currency}
            </p>
          </div>
          <div className={styles.planDetails}>
            <p>{discountPercentage}% introductory price discount</p>
            <p>
              <span>
                {" "}
                -{(priceOld - priceNew).toFixed(2)} {currency}
              </span>
            </p>
          </div>
        </div>

        <div className={styles.section}>
          <div className={styles.paymentSummary}>
            <p>Total per day</p>
            <p>
              <span>
                {perDay} {currency}
              </span>
            </p>
          </div>
          <div className={styles.totalAmount}>
            <p>Total</p>
            <p>
              {priceNew} {currency}{" "}
              <span>for {durationText.replace(/-/g, " ")}</span>
            </p>
          </div>
          <p className={styles.discountText}>
            You just saved {(priceOld - priceNew).toFixed(2)} {currency} (
            {discountPercentage}% off)
          </p>
        </div>

        <h3 className={styles.paymentTitle}>Select your payment method</h3>

        <div className={styles.paymentMethods}>
          <div 
            className={styles.paymentMethod}
            onClick={() => handlePaymentMethodChange("one-click")}
          >
            <label>
              <input
                type="radio"
                name="paymentMethod"
                checked={selectedPaymentMethod === "one-click"}
                onChange={() => {}}
              />
              One-click payment
            </label>
            {selectedPaymentMethod === "one-click" && (
              <div className={styles.paymentContent}>
                <PaymentButton id={`paywall_continue_modal_${idPrefix}`} />
                <PaddleCheckout id="paywall_continue_modal_paypal" />
                <p>The safer, easier way to pay</p>
              </div>
            )}
          </div>

          <div 
            className={styles.paymentMethod}
            onClick={() => handlePaymentMethodChange("credit-card")}
          >
            <label>
              <input
                type="radio"
                name="paymentMethod"
                checked={selectedPaymentMethod === "credit-card"}
                onChange={() => {}}
              />
              Credit card
            </label>
            {selectedPaymentMethod === "credit-card" && (
              <div className={styles.paymentContent}>
                <Card id="paywall_continue_modal_card" isInModal />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
