import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { CSSTransition } from "react-transition-group";
import styles from "./styles.module.scss";
import { useRef } from "react";

export const ModalContainer = NiceModal.create(({ children, onClose }) => {
  const modalRef = useRef(null);
  const modal = useModal();

  console.log(modal.visible);

  return (
    <CSSTransition
      in={modal.visible}
      timeout={200}
      classNames={{
        enter: styles.modalEnter,
        enterActive: styles.modalEnterActive,
        exit: styles.modalExit,
        exitActive: styles.modalExitActive,
      }}
      unmountOnExit
      nodeRef={modalRef}
    >
      <div ref={modalRef} className={styles.modalOverlay}>
        <div className={styles.content}>{children}</div>
      </div>
    </CSSTransition>
  );
});
