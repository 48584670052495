import { Route, Routes } from "react-router-dom";
import { OnboardingStepper } from "./stepper";
import { CurrentStep } from "./stepper/CurrentStep";
import { Paywall } from "./paywall";
import {
  Prediction,
  CreatingPlan,
  Email,
  Name,
  PlanChart,
  FinalQuestionsWrapper,
} from "./finalQuestions";
import { Upsale } from "../shared/components/upsale";
import { RoutesWithEmail } from "@api/RoutesWithEmail";
export const Easyfit = () => {
  return (
    <Routes>
      <Route element={<OnboardingStepper />} path="/onboarding">
        <Route element={<CurrentStep />} path="/onboarding/:stepIndex" />
      </Route>
      <Route element={<FinalQuestionsWrapper />}>
        <Route path="/prediction" element={<Prediction />} />
        <Route path="/creating-plan" element={<CreatingPlan />} />
        <Route path="/email" element={<Email />} />
        <Route path="/name" element={<Name />} />
        <Route path="/plan-chart" element={<PlanChart />} />
      </Route>
      <Route element={<RoutesWithEmail />}>
        <Route element={<Paywall />} path="/paywall" />
        <Route element={<Upsale />} path="/upsale" />
      </Route>
    </Routes>
  );
};
