import { Route, Routes } from "react-router-dom";
import { OnboardingStepper } from "./stepper";
import { Paywall } from "./paywal";
import {
  FinalQuestionsWrapper,
  Prediction,
  CreatingPlan,
} from "./finalQuestions";
import { PreRegistration } from "./stepper/questions/preRegistration";
import { Email } from "./stepper/questions/email";
import { Name } from "./stepper/questions/name";
import { Newsletter } from "./stepper/questions/newsletter";
import { Confident } from "./stepper/questions/confident";
import { PlanChart } from "./stepper/questions/planChart";
import { Upsale } from "../shared/components/upsale";
import { CurrentStep } from "./stepper/CurrentStep";
import { RoutesWithEmail } from "@api/RoutesWithEmail";
import { UserActivation } from "src/routes/activate";
import { ThankyouPage } from "src/routes/thankyoupage";

export const EasyfitQuiz = () => {
  return (
    <Routes>
      <Route element={<OnboardingStepper />} path="/onboarding">
        <Route element={<CurrentStep />} path="/onboarding/:stepIndex" />
      </Route>
      <Route element={<RoutesWithEmail />}>
        <Route element={<Paywall />} path="/paywall" />
        <Route element={<Upsale />} path="/upsale" />
        <Route element={<UserActivation />} path="/activate" />
        <Route element={<ThankyouPage />} path="/thankyou" />
      </Route>
      <Route element={<FinalQuestionsWrapper />}>
        <Route path="/creating-plan" element={<CreatingPlan />} />
        <Route path="/take-step" element={<PreRegistration />} />
        <Route path="/email" element={<Email />} />
        <Route path="/news" element={<Newsletter />} />
        <Route path="/name" element={<Name />} />
        <Route path="/prediction" element={<Prediction />} />
        <Route path="/confident" element={<Confident />} />
        <Route path="/plan-chart" element={<PlanChart />} />
      </Route>
    </Routes>
  );
};
