import { generateClassList } from "@utils/generateClassList";
import styles from "./styles.module.scss";

export const IconCard = ({
  img,
  title,
  onClick,
  paragraph,
  active,
  fullWidth,
}) => {
  const classes = {
    active,
    fullWidth,
  };
  return (
    <button
      className={generateClassList(styles.card, classes, styles)}
      onClick={onClick}
    >
      {img && (
        <div className={styles.icon}>
          <img src={img} alt={title} />
        </div>
      )}
      <div className={styles.text}>
        {title}

        {paragraph && <p className={styles.paragraph}>{paragraph}</p>}
      </div>
    </button>
  );
};
