import { OnboardingLayout } from "@common/onboardingLayout";
import { Outlet } from "react-router";

export { Email } from "../stepper/questions/registration/email";
export { Name } from "../stepper/questions/registration/name";
export { Prediction } from "../../shared/components/prediction";
export { PlanChart } from "../stepper/questions/registration/planChart";
export { CreatingPlan } from "../stepper/questions/registration/creatingPlan";

export const FinalQuestionsWrapper = () => {
  return (
    <OnboardingLayout>
      <Outlet />
    </OnboardingLayout>
  );
};
