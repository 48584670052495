import { OptionsWithBackground } from "../../../../shared";
import background from "./bg.png";

const data = [
  {
    displayValue: "Not familiar at all",
    value: "1",
  },
  {
    value: "2",
    displayValue: "Heard of it, but never tried",
  },
  {
    value: "3",
    displayValue: "I have some experience",
  },
  {
    value: "4",
    displayValue: "I'm proficient",
  },
];

export const KnowAboutSomatic = ({ onNextStep }) => {
  const handleNextStep = (v) => {
    console.log(v);
    onNextStep?.();
  };
  return (
    <OptionsWithBackground
      data={data}
      title="How much do you know about somatic exercises?"
      onClick={handleNextStep}
      background={background}
    />
  );
};
