import { useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Steps } from "./data/steps";
// import { updateAnswer } from "../../../redux/slices/answersSlice";
import styles from "./styles.module.scss";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import "./fade.css";
import { UserService } from "@api/services/userService";

export const CurrentStep = () => {
  const { stepIndex } = useParams();
  const navigate = useNavigate();
  const nodeRef = useRef();

  let currentStep;
  if (isNaN(stepIndex)) {
    currentStep = Steps.find((step) => step.uri === stepIndex);
  } else {
    const index = parseInt(stepIndex, 10) - 1;
    currentStep = Steps[index];
  }

  const Component = currentStep.component;

  const handleNextStep = (answer) => {
    const mappedKey = currentStep?.mappingKey;

    if (answer !== undefined && mappedKey) {
      UserService.updateQuestions({ [mappedKey]: answer });
    }

    if (answer === null && mappedKey === "qqOccasion") {
      navigate("/creating-plan");
      return;
    }

    const currentStepIndex = Steps.indexOf(currentStep);

    if (currentStepIndex + 1 >= Steps.length) {
      navigate("/creating-plan");
      return;
    }

    const nextStep = Steps[currentStepIndex + 1];
    const nextStepUri = nextStep.uri || (currentStepIndex + 2).toString();

    navigate(`/onboarding/${nextStepUri}`);
  };

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={stepIndex}
        in={true}
        nodeRef={nodeRef}
        timeout={700}
        classNames="fade-in"
      >
        <div ref={nodeRef} className={styles.step}>
          <Component onNextStep={handleNextStep} />
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
};
