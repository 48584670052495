import { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { Button } from "../button";
import { set } from "react-hook-form";

export const KeyboardButton = ({ onClick, disabled, children }) => {
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);
  const [height, setHeight] = useState(
    window.innerHeight - window.visualViewport.height
  );
  const [isExiting, setIsExiting] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.visualViewport) {
        const isKeyboard = window.visualViewport.height < window.innerHeight;
        const scrollPosition = window.scrollY;
        setHeight(window.innerHeight - window.visualViewport.height);
        if ((!isKeyboard && isKeyboardVisible) || scrollPosition > 100) {
          setIsExiting(true);
          setIsKeyboardVisible(false);
          setIsExiting(false);
        } else {
          setIsKeyboardVisible(isKeyboard);
        }
      }
    };
    window.addEventListener("scroll", handleResize);

    window.visualViewport?.addEventListener("resize", handleResize);

    return () => {
      window.visualViewport?.removeEventListener("scroll", handleResize);
      window.visualViewport?.removeEventListener("resize", handleResize);
    };
  }, [isKeyboardVisible]);

  if (!isKeyboardVisible) return null;

  return (
    <div
      style={{
        bottom: `${height}px`,
      }}
      className={`${styles.keyboardButton} ${isExiting ? styles.exit : ""}`}
    >
      <Button onClick={onClick} disabled={disabled}>
        {children}
      </Button>
    </div>
  );
};
