import { Button } from "@common/index";
import styles from "./styles.module.scss";
import { useGetUserInfo } from "@hooks/useGetUserProfile";
import { Loader } from "@common/loader";
import { getPredictionData } from "./utils/getPredictionData";
import { useNavigate } from "react-router";
import {
  ChartGainWeight,
  ChartLoseWeight,
  ChartMaintainWeight,
} from "./charts";

export const Prediction = () => {
  const isSomatic = process.env.REACT_APP_PRODUCT === "SOMATIC";

  const navigate = useNavigate();
  const handleNextStep = () => {
    navigate("/confident");
  };
  const { user, loaded } = useGetUserInfo();
  const ms = localStorage.getItem("ms");
  const {
    weight,
    goal,
    predictedWeight,
    predictedDate,
    event,
    eventResponse,
    eventDate,
    isLoseWeight,
    isGainWeight,
    weightChange,
    axisY,
  } = getPredictionData(user);

  return (
    <>
      {loaded ? (
        <div className={styles.container} id="breakdown">
          <div className={styles.content}>
            <div className={styles.text}>
              <h2>The only program you'll ever need to get your dream body</h2>
              <p className={styles.subtitle}>We predict you'll be</p>
              <p className={styles.predictedData}>
                {goal} {ms === "us" ? "lbs" : "kg"} <span>by</span>{" "}
                {new Date(predictedDate).toLocaleString("en-US", {
                  month: "long",
                  day: "2-digit",
                  year: "numeric",
                })}
              </p>
              {eventResponse && eventDate && (
                <div className={styles.event}>
                  {`~${Math.round(predictedWeight - weight) || weightChange} ${
                    ms === "us" ? "lbs" : "kg"
                  }`}{" "}
                  <span>by the</span> {event?.displayValue}{" "}
                  <div className={styles.icon}>{event?.icon}</div>
                </div>
              )}
            </div>

            <div
              className={
                loaded ? styles.chart : `${styles.chart} ${styles.hidden}`
              }
            >
              {eventResponse && eventDate && (
                <div className={styles.eventLabel}>
                  {event?.displayValue} -{" "}
                  {new Date(eventDate).toLocaleDateString("en-US", {
                    month: "long",
                    day: "2-digit",
                    year: "numeric",
                  })}
                </div>
              )}
              <div className={styles.axisx}>
                <span>
                  {" "}
                  {new Date().toLocaleString("en-US", {
                    month: "short",
                    day: "2-digit",
                  })}
                </span>
                <span>
                  {" "}
                  {new Date(predictedDate).toLocaleString("en-US", {
                    month: "short",
                    day: "2-digit",
                  })}
                </span>
              </div>
              <div className={styles.axisy}>
                {axisY.map((value, index) => {
                  return <span key={index}>{value}</span>;
                })}
              </div>
              <div
                className={
                  isLoseWeight
                    ? `${styles.label}`
                    : isGainWeight
                    ? `${styles.label} ${styles.gain}`
                    : isSomatic
                    ? `${styles.label} ${styles.maintain}`
                    : `${styles.label}`
                }
              >
                Goal <br />
                {goal} {ms === "us" ? "lbs" : "kg"}
              </div>

              {isLoseWeight ? (
                <ChartLoseWeight />
              ) : isGainWeight ? (
                <ChartGainWeight />
              ) : isSomatic ? (
                <ChartMaintainWeight />
              ) : (
                <ChartLoseWeight />
              )}
            </div>
            <div className={styles.hint}>
              *Established on the data provided by the users to the app. Check
              with your doctor first. This chart is just a visual representation
              of a possible fitness journey.
            </div>
          </div>

          <div className={styles.action}>
            <Button onClick={handleNextStep}>Continue</Button>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};
