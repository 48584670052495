import styles from "./styles.module.scss";

export const ImageCard = ({ img, text, checked = false, onClick }) => {
  return (
    <button
    onClick={onClick}
      className={
        checked
          ? `${styles.imageCard} ${styles.checked}`
          : `${styles.imageCard}`
      }
    >
      <img src={img} alt={text} />
      <div className={styles.label}>{text}</div>
    </button>
  );
};
