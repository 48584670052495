import styles from './styles.module.scss';

export const MeasurementsSwitch = ({ units, ms, changeMs }) => {
  return (
    <div className={styles.switch}>
      <div
        onClick={() => changeMs("us")}
        className={
          ms === "us" ? `${styles.item} ${styles.active}` : `${styles.item}`
        }
      >
        {units[0]}
      </div>
      <div
        onClick={() => changeMs("eu")}
        className={
          ms === "eu" ? `${styles.item} ${styles.active}` : `${styles.item}`
        }
      >
        {units[1]}
      </div>
    </div>
  );
};
