import {
  PeopleChosen,
  Initiate,
  MainGoal,
  TransformBody,
  HopeToAchieve,
  StruggleSetbacks,
  ChooseBodyType,
  CompositionWantToHave,
  WeightFluctulate,
  SlimDown,
  WithoutStress,
  ChooseTargetZones,
  LoadingChart,
  ChooseFocus,
  OftenWorkout,
  YouAwesome,
  TypicalDay,
  WeKnow,
  Age,
  Breakfast,
  Breathing,
  BurnFat,
  Dinner,
  DietTypes,
  EnergyLevels,
  EventDate,
  FitnessLevel,
  Flexible,
  FutureEvent,
  Height,
  IssuesStruggling,
  Lunch,
  NutritionStatements,
  PastEvents,
  PeakShape,
  ReduceTension,
  Report,
  Sleep,
  UsualDay,
  Walks,
  WaterIntake,
  Weight,
  WeightGoal,
  ReadyToLose,
} from "../questions";

export const groupTitles = [
  "My Profile",
  "Activity",
  "Lifestyle & Habbits",
  "Nutrition",
  "Almost Done",
];

export const Steps = [
  { groupIndex: 0, component: Initiate },
  { groupIndex: 0, component: PeopleChosen },
  { groupIndex: 0, component: MainGoal, headerTitle: true },
  { groupIndex: 0, component: WeKnow },
  {
    groupIndex: 0,
    component: HopeToAchieve,
    headerTitle: true,
  },

  {
    groupIndex: 0,
    component: TransformBody,
  },

  {
    groupIndex: 0,
    component: ChooseBodyType,
    headerTitle: true,
  },

  {
    groupIndex: 0,
    component: CompositionWantToHave,
    headerTitle: true,
    mappingKey: "qqGoal",
  },

  {
    groupIndex: 0,
    component: SlimDown,
  },
  { groupIndex: 0, component: PeakShape, headerTitle: true },
  {
    groupIndex: 1,
    component: FitnessLevel,
    mappingKey: "qqFitnessLevel",
    headerTitle: true,
  },
  {
    groupIndex: 1,
    component: ChooseTargetZones,
    headerTitle: true,
  },
  { groupIndex: 1, component: BurnFat },
  {
    groupIndex: 1,
    component: Flexible,
    mappingKey: "qqFlexibility",
    headerTitle: true,
  },
  {
    groupIndex: 1,
    component: Breathing,
    mappingKey: "qqStairs",
    headerTitle: true,
  },
  { groupIndex: 1, component: IssuesStruggling, headerTitle: true },
  { groupIndex: 1, component: ReduceTension },
  {
    groupIndex: 1,
    component: StruggleSetbacks,
    headerTitle: true,
  },
  {
    groupIndex: 1,
    component: WeightFluctulate,
    mappingKey: "qqWeightFluctuation",
    headerTitle: true,
  },
  {
    groupIndex: 1,
    component: ReadyToLose,
    headerTitle: true,
  },
  {
    groupIndex: 1,
    component: ChooseFocus,
    headerTitle: true,
  },
  {
    groupIndex: 2,
    component: WithoutStress,
  },
  {
    groupIndex: 2,
    component: OftenWorkout,
    headerTitle: true,
    mappingKey: "qqExerciseFrequency",
  },
  {
    groupIndex: 2,
    component: YouAwesome,
  },
  {
    groupIndex: 2,
    component: TypicalDay,
    headerTitle: true,
    mappingKey: "qqDayRoutine",
  },
  {
    groupIndex: 2,
    component: Walks,
    headerTitle: true,
    mappingKey: "qqWalksFrequency",
  },
  {
    groupIndex: 2,
    component: UsualDay,
    headerTitle: true,
    mappingKey: "qqDayRoutine",
  },
  {
    groupIndex: 2,
    headerTitle: true,
    component: EnergyLevels,
    mappingKey: "qqEnergyLevels",
  },
  {
    groupIndex: 2,
    component: WaterIntake,
    mappingKey: "qqDailyWaterIntake",
    headerTitle: true,
  },
  {
    groupIndex: 2,
    component: Sleep,
    mappingKey: "qqSleep",
    headerTitle: true,
  },
  {
    groupIndex: 3,
    component: Breakfast,
    mappingKey: "qqBreakfastTime",
    headerTitle: true,
  },
  {
    groupIndex: 3,
    component: Lunch,
    mappingKey: "qqLunchTime",
    headerTitle: true,
  },
  {
    groupIndex: 3,
    component: Dinner,
    mappingKey: "qqDinnerTime",
    headerTitle: true,
  },
  {
    groupIndex: 3,
    component: DietTypes,
    headerTitle: true,
  },
  {
    groupIndex: 3,
    component: NutritionStatements,
    headerTitle: true,
  },
  {
    groupIndex: 4,
    component: PastEvents,
    headerTitle: true,
  },

  {
    groupIndex: 4,
    component: Height,
    headerTitle: true,
  },
  {
    groupIndex: 4,
    component: Weight,
    headerTitle: true,
  },
  {
    groupIndex: 4,
    component: WeightGoal,
    headerTitle: true,
  },
  {
    groupIndex: 4,
    component: Age,
    headerTitle: true,
    mappingKey: "qqAge",
  },
  {
    groupIndex: 4,
    component: Report,
    headerTitle: true,
  },
  {
    groupIndex: 4,
    component: FutureEvent,
    headerTitle: true,
    mappingKey: "qqOccasion",
  },
  {
    groupIndex: 4,
    component: EventDate,
    headerTitle: true,
    mappingKey: "qqOccasionDate",
  },
];
