import { generateClassList } from "@utils/generateClassList";
import { Checkbox, CheckboxChecked } from "../../../../../assets/Checkbox";
import styles from "./styles.module.scss";

export const IconCardCheckbox = ({
  img,
  title,
  onClick,
  paragraph,
  checked,
  fullWidth,
}) => {
  const classes = {
    checked,
    fullWidth,
  };
  return (
    <button
      className={generateClassList(styles.card, classes, styles)}
      onClick={onClick}
    >
      <div className={styles.content}>
        {img && (
          <div className={styles.icon}>
            <img src={img} alt={title} />
          </div>
        )}
        <div className={styles.text}>
          {title}
          {paragraph && <p className={styles.paragraph}>{paragraph}</p>}
        </div>
      </div>
      <div className={styles.checkbox}>
        {checked ? <CheckboxChecked /> : <Checkbox />}
      </div>
    </button>
  );
};
